/* eslint-disable no-undef */
import DB from './db';
import { OBJECT_STORES } from './constants';

export default () => {
	const wishesStore = OBJECT_STORES.wishlist;
	const option = { storage: wishesStore };
	const db = DB();
	db.load(0, option).then((wishlist) => {
		$('<style>.wishlist a.__coup-de-coeured:before {background-color: var(--color-secondary) !important;} .wishlist a.__coup-de-coeured{color: var(--color-white) !important;}</style>').appendTo('head');
		const w = wishlist;
		const $btns = document.querySelectorAll('.wishlist a');
		$btns.forEach((btn) => {
			if (!btn.getAttribute('href')?.includes('coups-de-coeur')) return;
			const { id } = btn.dataset;
			if (id && w.find((row) => row.productId === Number(id))) {
				btn.classList.add('__coup-de-coeured');
				btn.parentElement.setAttribute('title', 'Retirer de la liste de souhaits');
			}
		});
		$(document).on('click', '.wishlist a', (e) => {
			const btn = $(e.currentTarget);
			if (!btn.attr('href')?.includes('coups-de-coeur') || btn.closest('header').length > 0) return;
			e.preventDefault();
			const id = btn.data('id');
			const uri = btn.attr('href');
			// Send request to server
			$.ajax({
				url: uri,
				type: 'POST',
				dataType: 'JSON',
				data: { produit_id: id },
				beforeSend() {
					// Save it to local IndexedDB
					if (wishlist?.find((row) => row.productId === Number(id))) {
						// Remove from wishlist
						btn.removeClass('__coup-de-coeured').parent().attr('title', 'Ajouter à la liste de souhaits');
						db.remove(id, option).then(() => {
							wishlist.splice(wishlist.findIndex((row) => row.productId === Number(id)), 1);
						});
					} else {
						// Add to wishlist
						btn.addClass('__coup-de-coeured').parent().attr('title', 'Retirer de la liste de souhaits');
						db.put({ id, productId: id }, { ...option, encrypt: true }).then(() => {
							wishlist.push({ id, productId: id });
						});
					}
				},
				async success(response) {
					const sessionStore = { storage: OBJECT_STORES.sessionKeys };
					if (Object.hasOwn(response, ['client_id'])) {
						db.put({ id: 1, key: 'client_id', value: response.client_id }, sessionStore);
					} else if (Object.hasOwn(response, ['session_id'])) {
						await db.put({ id: 1, key: 'session_id', value: response.session_id }, sessionStore);
					}
				},
			});
		});

		// Remove from wishlist
		$(document).on('click', '.remove-wishlist', (e) => {
			e.preventDefault();
			const $this = $(e.currentTarget);
			const tableRow = $this.closest('tr');
			const productId = $this.data('id');
			$.ajax({
				url: $this.attr('href'),
				type: 'post',
				dataType: 'json',
				data: { _method: 'DELETE' },
				beforeSend() {
					tableRow.fadeOut('slow');
				},
				success() {
					tableRow.remove();
					db.remove(productId, option);
				},
				error(error) {
					tableRow.fadeIn('slow');
					// eslint-disable-next-line no-alert
					alert(error.responseJSON?.message ?? `Erreur : ${error.statusText}`);
				},
			});
		});
	});
};
