/* eslint-disable no-undef */
import { WAITME_CONFIG } from './constants';

export default () => {
	try {
		$.ajaxSetup({
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				'X-Requested-With': 'XMLHttpRequest',
				Accept: 'application/json; charset=utf-8, text/plain, */*',
			},
		});
	} catch (error) {
		// eslint-disable-next-line no-alert
		alert(error.message);
	}
	$(document).on('submit', 'form.async', (e) => {
		let $wrapper = $(e.target).closest('.block-ui');
		// If no wrapper, use main
		if (!$wrapper.length) {
			if ($('main').length) $wrapper = $('main');
			else if ($('body').length) $wrapper = $('body');
			else $wrapper = $(e.target);
		}
		$wrapper.waitMe(WAITME_CONFIG);
		const form = $(e.target);
		const formData = new FormData(form[0]);
		const jsonpData = form.data('jsonp');
		const dataType = (jsonpData ? 'jsonp' : 'json').toUpperCase();
		let ajaxOptions = {
			url: form.attr('action'),
			type: form.attr('method'),
			dataType,
			data: formData,
			processData: false,
			contentType: false,
		};
		if (dataType === 'JSONP') {
			ajaxOptions = {
				...ajaxOptions,
				jsonp: jsonpData.param,
				jsonpCallback: jsonpData.callback,
			};
		}
		// Remove all previous error messages, invalid classes, and alerts
		form.find('.is-invalid').removeClass('is-invalid');
		form.find('.invalid-feedback, .alert').remove();
		form.find('.form-results').html('').addClass('d-none');

		$.ajax({
			...ajaxOptions,
			complete() {
				$wrapper.waitMe('hide');
			},
			error(error) {
				let errorMessage = 'Le formulaire contient des erreurs. Veuillez les corriger avant de soumettre à nouveau.';
				if (error.status === 422) {
					const fields = form.find('input, select, textarea');
					let errors = [];
					if (Object.hasOwn(error, 'responseJSON') && Object.hasOwn(error.responseJSON, 'errors')
						&& Object.keys(error.responseJSON.errors).length) {
						errors = error.responseJSON.errors;
					} else {
						errors = JSON.parse(error.responseText).errors;
					}
					Object.keys(errors).forEach((fieldName) => {
						let fieldNameAttr = fieldName;
						// If fieldName if formatted using dot notation for arrays,
						// then reformat it using brackets instead
						if (fieldName.includes('.')) {
							const parts = fieldName.split('.');
							[fieldNameAttr] = parts;
							for (let index = 1; index < parts.length; index += 1) {
								fieldNameAttr += `[${parts[index]}]`;
							}
						}
						const field = fields.filter(`[name="${fieldNameAttr}"]`);
						field.addClass('is-invalid');
						let errorMessages = '';
						if (errors[fieldName].length > 1) {
							Object.keys(errors[fieldName]).forEach((msg) => {
								errorMessages += `<li>${errors[fieldName][msg]}</li>`;
							});
							field.after(`<div class="invalid-feedback text-danger"><ul>${errorMessages}</ul></div>`);
						} else {
							errorMessages += errors[fieldName][0];
							field.after(`<div class="invalid-feedback text-danger">${errorMessages}</div>`);
						}
					});
				} else {
					errorMessage = error.responseJSON?.message || JSON.parse(error.responseText).message;
				}
				errorMessage = error.responseJSON?.message || JSON.parse(error.responseText).message;
				// Create an alert box to show the error message
				const alert = $(`<div class="alert alert-danger alert-dismissible fade show my-3" role="alert">${errorMessage}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Fermer"></button></div>`);
				if (form.find('.form-results').length) {
					form.find('.form-results').html(alert).removeClass('d-none');
				} else {
					const submitButton = form.find('button[type="submit"], input[type="submit"], .submit');
					// If the button is inside a .form-group or a .d-flex,
					// add the alert before that instead of before the button
					if (submitButton.closest('.form-group, .d-flex').length) {
						submitButton.closest('.form-group, .d-flex').before(alert);
					} else {
						submitButton.before(alert);
					}
				}
			},
			success(responseData) {
				let successMessage = 'Terminé avec succès !';
				if (typeof responseData.message !== 'undefined' && responseData.message.length) {
					successMessage = responseData.message;
				}
				// Create an alert box to show the success message
				const alert = $(`<div class="alert alert-success d-block alert-dismissible fade show my-3" role="alert">${successMessage}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`);
				if (form.find('.form-results').length) {
					form.find('.form-results').html(alert).removeClass('d-none');
				} else {
					const submitButton = form.find('button[type="submit"], input[type="submit"], .submit');
					// If the button is inside a .form-group or a .d-flex,
					// add the alert before that instead of before the button
					if (submitButton.closest('.form-group, .d-flex').length) {
						submitButton.closest('.form-group, .d-flex').before(alert);
					} else {
						submitButton.before(alert);
					}
				}
				// Remove the alert after 3 seconds
				setTimeout(() => {
					if (form.find('.form-results').length) {
						form.find('.form-results').html('').addClass('d-none');
					} else alert.fadeOut('slow', () => { alert.remove(); });
				}, 5000);
				// If there is a redirect, redirect after 1 second
				if (dataType !== 'JSONP' && typeof responseData.redirect !== 'undefined') {
					setTimeout(() => {
						document.location.href = responseData.redirect;
					}, 1000);
				} else if (form.hasClass('should-reset')) {
					form[0].reset();
				}
			},
		});
		return false;
	});
};
